:root, [data-theme="dark"] {
    --text-50: #e9f7fc;
    --text-100: #d2eff9;
    --text-200: #a5dff3;
    --text-300: #78d0ed;
    --text-400: #4bc0e7;
    --text-500: #1fb0e0;
    --text-600: #188db4;
    --text-700: #126a87;
    --text-800: #0c465a;
    --text-900: #06232d;
    --text-950: #031216;


    --background-50: #0b0d0e;
    --background-100: #161b1d;
    --background-200: #2d3539;
    --background-300: #435056;
    --background-400: #5a6a72;
    --background-500: #70858f;
    --background-600: #8d9da5;
    --background-700: #a9b6bc;
    --background-800: #c6ced2;
    --background-900: #e2e7e9;
    --background-950: #f1f3f4;

    --primary-50: #031216;
    --primary-100: #06252d;
    --primary-200: #0d4a59;
    --primary-300: #136f86;
    --primary-400: #1994b3;
    --primary-500: #20b9df;
    --primary-600: #4cc7e6;
    --primary-700: #79d5ec;
    --primary-800: #a6e3f2;
    --primary-900: #d2f1f9;
    --primary-950: #e9f8fc;

    --secondary-50: #011817;
    --secondary-100: #03302f;
    --secondary-200: #06605d;
    --secondary-300: #08918c;
    --secondary-400: #0bc1bb;
    --secondary-500: #0ef1e9;
    --secondary-600: #3ef4ee;
    --secondary-700: #6ef7f2;
    --secondary-800: #9ff9f6;
    --secondary-900: #cffcfb;
    --secondary-950: #e7fefd;

    --accent-50: #00001a;
    --accent-100: #000033;
    --accent-200: #000066;
    --accent-300: #000099;
    --accent-400: #0000cc;
    --accent-500: #0000ff;
    --accent-600: #3333ff;
    --accent-700: #6666ff;
    --accent-800: #9999ff;
    --accent-900: #ccccff;
    --accent-950: #e5e5ff;

        --inactive-tab-shadow: rgba(0, 0, 0, 0.72);
        --active-tab-shadow: rgba(255, 255, 255, 0.6);
        --netural: #364149
}

:root[data-theme="light"] {

    --text-50: #031216;
    --text-100: #06232d;
    --text-200: #0c465a;
    --text-300: #126a87;
    --text-400: #188db4;
    --text-500: #1fb0e0;
    --text-600: #4bc0e7;
    --text-700: #78d0ed;
    --text-800: #a5dff3;
    --text-900: #d2eff9;
    --text-950: #e9f7fc;

    --background-50: #e8f9fc;
    --background-100: #d2f2f9;
    --background-200: #a4e5f4;
    --background-300: #77d8ee;
    --background-400: #49cbe9;
    --background-500: #1cbee3;
    --background-600: #1698b6;
    --background-700: #117288;
    --background-800: #0b4c5b;
    --background-900: #06262d;
    --background-950: #031317;

    --primary-50: #e9f8fc;
    --primary-100: #d2f1f9;
    --primary-200: #a6e3f2;
    --primary-300: #79d5ec;
    --primary-400: #4cc7e6;
    --primary-500: #20b9df;
    --primary-600: #1994b3;
    --primary-700: #136f86;
    --primary-800: #0d4a59;
    --primary-900: #06252d;
    --primary-950: #031216;

    --secondary-50: #e7fefd;
    --secondary-100: #cffcfb;
    --secondary-200: #9ff9f6;
    --secondary-300: #6ef7f2;
    --secondary-400: #3ef4ee;
    --secondary-500: #0ef1e9;
    --secondary-600: #0bc1bb;
    --secondary-700: #08918c;
    --secondary-800: #06605d;
    --secondary-900: #03302f;
    --secondary-950: #011817;

    --accent-50: #e5e5ff;
    --accent-100: #ccccff;
    --accent-200: #9999ff;
    --accent-300: #6666ff;
    --accent-400: #3333ff;
    --accent-500: #0000ff;
    --accent-600: #0000cc;
    --accent-700: #000099;
    --accent-800: #000066;
    --accent-900: #000033;
    --accent-950: #00001a;

    --netural: #837da9
}


:root {
    --size-navbar: 4rem;
        --ascii-title-size: 1.5rem;
}

/*:root, [data-theme="dark"] {*/
/*    --color-primary: #3dffd2;*/
/*    --color-secondary: #00ded7;*/
/*    --color-background: #18191a;*/

/*    --color-text: #ffffff;*/
/*    --color-accent: #48CFCB;*/

/*    --card-background: #273041;*/
/*    --bg-art-color: #033103;*/
/*    --border-color: #000000;*/
/*    --highlight-color: #27ad5f;*/


/*    --black: #141415;*/



/*    --color-navbar: #387478;*/

/*    --grey-text: #c7c7c7;*/
/*}*/

/*[data-theme="light"] {*/
/*    --color-primary: #25ffd2;*/
/*    --color-secondary: #00425e;*/
/*    --color-background: #e9f0f6;*/
/*    --color-text: #000000;*/
/*    --color-accent: #19a6b9;*/

/*    --card-background: rgba(103, 126, 173, 0.51);*/
/*    --bg-art-color: #e2e2f3;*/

/*    --border-color: #000000;*/
/*    --highlight-color: #27ad5f;*/
/*    --grey-text: #4b4b4b;*/


/*}*/


body {
    margin: var(--size-navbar) 0 0;
    font-family:  BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

blockquote {
    padding: 1rem;
    margin: 1rem 0;
    border-left: 4px solid #3498db;
    color: #555;
}

code {
    padding: 0.25rem 0.5rem;
    border-radius: 3px;
    font-family: cmd,monospace;
}

pre {
    color: #ecf0f1;
    padding: 1rem;
    overflow: auto;
    line-height: 1rem;
    font-family: monospace;
    white-space: pre;

}

.page-title {
    align-self: center;
    text-align: center;
}

/* CSS Reset */
*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
#root{
    height: fit-content;
}
html, body{
    height: 100%;
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

img, video {
    max-width: 100%;
    height: auto;
    display: block;
}

a {
    text-decoration: none;
    color: inherit;
}

ul, ol {
    list-style: none;
}

/* Typography */
h1, h2, h3, h4 {
    color: var(--text-50);
    margin-bottom: 0.75rem;
}

h1 {
    font-size: 2.5rem;
    font-family: titles, monospace;
}

h2 {
    font-size: 1.7rem;
    font-family: subtitle, monospace;

}

h3 {
    font-size: 1.2rem;
    font-family: subtitle, monospace;

}

h4 {
    font-size: 1rem;

}


p {
    font-size: 1.2rem;
    color: var(--text-50);
    font-family: texts, monospace;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.37);
    line-height: 2rem;

}

a {
    transition: color 0.2s ease-in-out;
}

a:hover {
}

a:visited {
    text-decoration: none;
}

