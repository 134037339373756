/* Container for the whole card */
.window98-card {
    /*width: 70%;*/
    border: 3px solid var(--background-50);
    border-top-color: var(--background-900);
    border-left-color: var(--background-900);
    border-radius: 4px;
    box-shadow: 2px 2px 0 #333;
    font-family: 'MS Sans Serif', sans-serif;
    background-color: var(--background-200);
    height: fit-content;
    /*margin: 20px auto;*/
}

/* Title bar styling */
.window98-title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--accent-300);
    color: #fff;
    padding: 4px;
    font-size: 14px;
}

/* Title text */
.window98-title {
    margin-left: 8px;
}

/* Window buttons styling */
.window98-buttons {
    display: flex;
    margin-right: 8px;
}

.window98-button {
    width: 1.5rem;
    border-radius: 0;
    font-weight: bold;
    color: #000;
    background-color: var(--netural);
    padding: 2px 6px;
    margin-left: 2px;
    border: 1px solid #000;
    box-shadow: 1px 1px 0px #fff inset;
    cursor: pointer;
}

.window98-button:hover {
    background-color: #6e8086;
}

/* Content area styling */
.window98-content {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 1rem;
    height: 100%;
    background-color: var(--background-100);
    border-top: 2px solid var(--background-50);
    border-left: 2px solid var(--background-50);
    border-right: 2px solid var(--text-50);
    border-bottom: 2px solid var(--text-50);
}
.cover-img{
    max-width: 200px;
    object-fit: cover;
}
.tech-stack {
    display: flex;
    align-items: center;

    flex-direction: row;
    gap: 5px;
}


@media (max-width: 1200px) {
    .cover-img{
        max-width: 200px;
        height: 100%;
    }
    .window98-card{
        width: 95%;

    }
    .window98-content{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

    }

    }

.blink {
    animation: blink 0.75s infinite ease-in;

}

.window>h1{
    color: var(--text-50);
}
.window>h2{
    color: var(--text-200);

}
.window>h3,.window>div{
    color: var(--text-300);
}
@keyframes blink {
    0%, 100% {
        opacity: 1;
    }
    30% {
        opacity: 0;
    }
}