

body {
  background-color: var(--background-50);
  color: var(--text-50);
  font-family: sans-serif;
}
.container {
  max-width: 80%;
  margin: 0 auto;
}

.center{
  margin-left: auto;
  margin-right: auto;
}
button {
  background-color: var(--secondary-50);
  color: var(--text-50);
  border: none;
  cursor: pointer;;
}

button:hover {
  background-color: var(--accent-50);
  color: var(--text-50);
}

.ascii-title {
  background-color: var(--background-50);
  font-size: var(--ascii-title-size);
  line-height: var(--ascii-title-size);
  color:var(--accent-700);
  overflow:hidden;
  text-align: center;
  width: 100%;
  border:none;
  box-shadow: none;

}
.ascii-title:before,.ascii-title:after{
  content:none
}
footer{
display: block;
  background-color: #222;
  color: var(--text-400);
  padding: 40px 20px;
  text-align: center;


}
.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: auto;
}

.footer-section {
  flex: 1;
  margin: 20px;
  min-width: 200px;
}

.footer-bottom>p{
  color: var(--background-500);
}
@media (max-width: 991px) {
  html{
    font-size: 16px;
  }
  :root{
    --ascii-title-size: 2vw;
  }

}

@media (max-width: 767px) {
  /* Styles for larger phones, e.g., iPhone 8 */
  html{
    font-size: 16px;
  }
  :root{
    --ascii-title-size: 2vw;

  }
}