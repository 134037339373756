.slideshow {

    align-items: center;
    display: block;
    width: fit-content;
    max-width: 90%;
    margin: auto;
    overflow: hidden;
    justify-content: space-between;
    height: fit-content ;

}

.slideshow>img{
    max-width: 100%;
    max-height: 600px;
}

.content {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 200px;
    max-width: 60%;
}

.next,.prev {
    height: fit-content;
    background-color: transparent;
    width: 100%;
    box-shadow: inset 0.2em 0.2em 0 0 rgb(213, 201, 201), inset -0.2em -0.2em 0 0 rgb(52, 51, 51);

}



.controls{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.controls>button:hover{
    background-color: transparent;
    box-shadow: inset 0.2em 0.2em 0 0 rgb(52, 51, 51), inset -0.2em -0.2em 0 0 rgb(213, 201, 201);
    color: var(--text-50);


}

.paragraph-content>p, .paragraph-content>p>strong {
    letter-spacing: -0.1rem;
    font-size: 1.5rem;
    margin-top: 50px;
    color: #e5efef;

}
.content>h1{
    font-size: 3rem;
}
.content>h2{
    font-size: 2rem;
}
.paragraph-content>h3{
    font-size: 2.5rem;

}

.project-card-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
    gap: 20px;
    padding: 20px 3rem;
}


@media (max-width: 991px) {

    .content{
        max-width: 90%;
    }

}

@media (max-width: 768px) {
    .project-card-container{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        gap: 20px;
        padding: 20px 3rem;
    }

}