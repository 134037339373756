.tech-icons-container {
    display: flex;
    gap: 2rem;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
}

.tech-icon {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    font-size: 3rem;
    color: #000000;
    transition: transform 0.3s;
    cursor: pointer;
}

.icon-container {
    display: inline-flex;
    color: var(--text-400);

    justify-content: center;
    align-items: center;
    width: 30px;  /* Adjust icon size */
    height: 30px; /* Adjust icon size */
}
.icon-container>p{
    font-size: 10px;
    color: var(--text-400);
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: fit-content;
    background-color: rgba(225, 217, 169, 0.68);
    box-shadow: var(--border-window-outer);

    border:black 1px solid;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    color: black;
    font-size: 1rem;
    text-align: center;
    padding: 10px;

    /* Position the tooltip */
    position: absolute;
    z-index: 10;
}

.tech-icon:hover .tooltiptext {
    visibility: visible;
}