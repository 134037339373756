
.console-command-container {
    display: flex;
    min-height: 90vh;
    flex-direction: column;


}

#commandHistory > div > a {
    text-decoration: underline;
    color: #db3434;
}

/*noinspection CssUnresolvedCustomProperty*/
#commandHistory {
    padding-left: 4px;
    padding-top: 20px;
    width: 100%;
    max-height: 85vh;
    background-color: var(--background-50);
    position: relative;
    overflow-y: auto;
    font-family: cmd, monospace;
    letter-spacing: 0.05rem;


}

#commandHistory > section > p {
    font-size: max(12px, 1vw);
    height: auto;

}

#commandHistory > section {
    font-family: cmd, monospace;
    color: var(--text-50);

    font-size: 1rem;
    height: auto;
}

/*noinspection CssUnresolvedCustomProperty*/
.consoleContainer {
    width: 100%;
    height: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: var(--background-50);
    padding-left: 4px;
    letter-spacing:0.1rem;
}

#consoleLabel {
    font-family: cmd, monospace;
    white-space: nowrap;
    padding-right: 2px;
}

/*noinspection CssUnresolvedCustomProperty*/
#console {
    width: 100%;
    height: 2.5rem;
    color: var(--text-50);
    font-size: max(1rem, 1vw);
    font-family: cmd, monospace;
    letter-spacing: 0.05rem;
    background-color: var(--background-50);
    margin: 0;
    resize: none;
    outline: none;
    border: none;
    box-shadow: none;
    caret-shape: underscore;

}

#console::placeholder {
    color: var(--text-600);
}

#console:focus {
    outline: none;

}

@media (max-width: 768px) {

    #consoleContainer, #commandHistory, #consoleContainer > input {
        font-size: 14px;
    }

    #commandHistory > section > h2 {
        font-size: 30px;
        height: auto;
    }
}

.command-history {
    padding: 0;
    font-family: cmd, monospace;
    letter-spacing: 0.1rem;
    color: var(--text-50);

}

.console-header {
    padding: 0; /* Merged padding from <pre> */
    margin: 0;

    font-family: cmd, monospace; /* Ensures cmd-style font */
    font-weight: normal;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    color: var(--text-50); /* Uses color from <pre> */
    overflow: auto;
    line-height: 1rem;
    white-space: pre; /* Keeps formatting */
}