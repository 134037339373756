
.retro-tabs {
    background-color: var(--primary-300);
    margin:0 auto;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    width: fit-content;
}

.retro-tab {
    margin:auto;
    flex:1;
    padding: 7px 1rem;

    font-family: monospace;
    font-size: 1.25rem;
    text-align: center;
    cursor: pointer;
    background-color: transparent;

    transition: background-color 0.1s, box-shadow 0.3s, color 0.3s;
    box-shadow: inset 2px 2px 0 0 var(--active-tab-shadow), inset -2px -2px 0 0 var(--inactive-tab-shadow);
    color: var(--color-text);
}

.retro-tab.active {
    background-color: var(--primary-100);

    box-shadow: inset 2px 2px 0 0 var(--inactive-tab-shadow), inset -2px -2px 0 0 var(--active-tab-shadow);
    color: var(--accent-700);
    transition: color 0.2s ease-in;
}

@media (max-width: 991px) {
    .retro-tab{
        font-size: 1rem;
    }
}